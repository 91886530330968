import React from 'react';
import PropTypes from 'prop-types';

class CentralFrame extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){
        return (
            <div style={{display:'flex', flexDirection : "column", ...this.props.style}}>
                <div className="CentralFrame">
                    {this.props.children}
                </div>
            <style jsx>{`
                    .CentralFrame {
                        min-height: 100vh;
                        width:100%;
                        overflow: hidden;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }    
                    
                `}</style>
            </div>
        )
    }
}

CentralFrame.propTypes = {
    
}

export default CentralFrame;