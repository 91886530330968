import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fonts, colors, effects } from '../../assets/css/theme';
import {Link} from 'react-router-dom';

class Dashboard extends React.Component {

    constructor(props){
        super(props);

    }

    render(){
        return (
            <div className="Dashboard">
                <h1 className="title">Welcome to the Arcadia Medical Customer Portal Admin Panel</h1>
                <p className="intro">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin ac rutrum tellus, sed consectetur lacus. Proin dictum rutrum tellus eget congue. </p>

                {/* <div className="buttons">
                    <div className="button" onClick={()=>{this.props.history.push('/catalogue')}}>
                        <img className="button-img"src={require('./../../assets/img/catalogue-icon.svg')} alt=""/>
                        <div className="button-title">Catalogue</div>
                        <div className="button-description">View products and create new orders</div>
                    </div>
                    <div className="button" onClick={()=>{this.props.history.push('/orders')}}>
                        <img className="button-img"src={require('./../../assets/img/orders-icon.svg')} alt=""/>
                        <div className="button-title">Orders</div>
                        <div className="button-description">View current and completed orders</div>
                    </div>
                    <div className="button"  onClick={()=>{this.props.history.push('/deliveries')}}>
                        <img className="button-img"src={require('./../../assets/img/deliveries-icon.svg')} alt=""/>
                        <div className="button-title">Deliveries</div>
                        <div className="button-description">View active and completed deliveries</div>
                    </div>
                    <div className="button"  onClick={()=>{this.props.history.push('/invoices')}}>
                        <img className="button-img"src={require('./../../assets/img/invoices-icon.svg')} alt=""/>
                        <div className="button-title">Invoices</div>
                        <div className="button-description">View invoices for past orders</div>
                    </div>

                </div> */}
                <style jsx>{`
                    .title {
                        ${fonts.bold};
                        font-size:22px;
                        color:${colors.dark};
                        margin:0px 0px 20px 0px;
                    }    
                    .intro {
                        ${fonts.reg};
                        color:${colors.dark};
                        margin:0px 0px 20px 0px;
                        max-width: 700px;
                    }
                    .buttons {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                    }
                    .button {
                        width:calc( 33% - 10px );
                        background: #fff;
                        padding:30px;
                        margin-bottom: 19px;
                        ${effects.shadow1}
                        transition: all 0.3s;
                        cursor: pointer;
                        border-radius: 5px;
                    }
                    .button:hover {
                        transform: scale(1.05);
                        background: ${colors.hiBlue};
                    }
                    .button-img {
                        width:100px;
                        height: 100px;
                        display: block;
                        margin:0px auto;
                    }
                    .button-title {
                        ${fonts.bold}
                        color:${colors.midBlue};
                        font-size:20px;
                        text-align: center;
                        margin-bottom:10px;
                        
                    }
                    .button-description {
                        ${fonts.bold}
                        color:${colors.dark};
                        font-size:12px;
                        text-align: center;
                        max-width:130px;
                        margin:auto;
                    }
                `}</style>
            </div>
        )
    }
}

Dashboard.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(Dashboard);

