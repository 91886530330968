const INITIAL_STATE = {
    connected : false,
    connecting : true
};

export default (state = INITIAL_STATE, action) => {
    let attrs;
    switch (action.type){
        case 'FIREBASE_INITIALISING':
            return { ...state, connected : false, connecting : true }
        case 'FIREBASE_CONNECTED':
            return { ...state, connected : true, connecting : false }
        default:
            return state;
    }
}