import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { dispatchCollectionSnapshot } from '../util/collectionUtils';
import { loadFirebase } from './appAction';

export const updateUserPermission = (userId, customerId)=>{
    return async (dispatch, getState)=> {
        return new Promise(async(resolve, reject)=>{
            let adminUserId = getState().auth.authedUser.uid;  
            try {
                let perm = {
                    customerId : customerId,
                    updatedAt : firebase.firestore.FieldValue.serverTimestamp(),
                    updatedBy : adminUserId
                }
                
                if(customerId){
                    await firebase.firestore().collection('permissions').doc(userId).set(perm,
                        {merge : true});
                } else {
                    await firebase.firestore().collection('permissions').doc(userId).delete();
                }
                
                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        })
        

    }
}

export const updateOrderStatusFromCustomerPortalToRemoved = (webOrderNum) => {
    return async (dispatch, getState) => {
        return new Promise(async(resolve, reject) => {

            try {
                if(webOrderNum) {
                    await firebase.firestore().collection('Orders').doc(webOrderNum).set({ Status: 'Removed' }, {merge: true})
                    firebase.firestore().collection('Orders')
                                        .where('source', "==", 'WEB')
                                        .where('Status', "!=", 'Removed')
                                        .orderBy('Status')
                                        .onSnapshot((snapShot)=>{dispatchCollectionSnapshot(snapShot, 'ORDERS_UPDATE')})
                }
                resolve(true);
            } catch (e) {
                reject(e);
            }
        })
    }
}

export const removeAdmin = (userId)=>{
    return async (dispatch, getState)=> {
        return new Promise(async(resolve, reject)=>{
            let adminUserId = getState().auth.authedUser.uid;  
            try {
                let removeAdmin = firebase.app().functions('europe-west2').httpsCallable('removeAdminUser');

                await removeAdmin({uid : userId});
                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        })
        

    }
}


export const removeUser = (userId)=>{
    return async (dispatch, getState)=> {
        return new Promise(async(resolve, reject)=>{
            try {
                let removeUser = firebase.app().functions('europe-west2').httpsCallable('removeUser');

                await removeUser({uid : userId});
                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        })
        

    }
}

export const getLoginLink = (email)=>{
    return async (dispatch, getState)=> {
        return new Promise(async(resolve, reject)=>{
            try {
                let getLoginLink = firebase.app().functions('europe-west2').httpsCallable('getLoginLink');

                let data = await getLoginLink({email : email});
                if(!data || !data.data || !data.data.link){
                    throw new Error('unable to create a link')
                }
                resolve(data.data.link);
            }
            catch(e){
                reject(e);
            }
        })
        

    }
}


export const addAdmin = (email)=>{
    return async (dispatch, getState)=> {
        return new Promise(async(resolve, reject)=>{
            let adminUserId = getState().auth.authedUser.uid;  
            try {
                let addAdmin = firebase.app().functions('europe-west2').httpsCallable('createAdmin');

                await addAdmin({email : email});
                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        })
        

    }
}