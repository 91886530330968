import _ from 'lodash';

const INITIAL_STATE = {
    customers : [],
    customersSelect : [],
    customersByKey : {},
    users : [],
    usersByKey : {},
    permissions : [],
    permissionsByUserId : {},
    orders : [],
    adminUsers : [],
    adminUsersByKey : {}
};


export default (state = INITIAL_STATE, action) => {
    let attrs = {};
    switch (action.type){
        case 'USERS_UPDATE':
            attrs.users=action.payload;
            attrs.usersByKey = {};
            attrs.users.forEach((r)=> {
                attrs.usersByKey[r.id] = r
            })
            return {...state, ...attrs};
        case 'CUSTOMERS_UPDATE':
            attrs.customers=action.payload;
            attrs.customersSelect = [];
            attrs.customersByKey = {};
            attrs.customers.forEach((r)=>{
                attrs.customersByKey[r.id] = r;
                attrs.customersSelect.push({
                    value : r.id,
                    label : `${r.id} : ${r.Name}`
                })
            })
            return {...state, ...attrs};
        
        case 'USER_PERMISSIONS_UPDATE':
            attrs.permissions = action.payload || [];
            attrs.permissions = !Array.isArray(attrs.permissions)
                ? [attrs.permissions]
                : attrs.permissions;
            attrs.permissionsByUserId = {};
            attrs.permissions.forEach((r)=>{
                attrs.permissionsByUserId[r.id] = r.customerId
            })
            return {...state, ...attrs};
        case 'ADMIN_USERS_UPDATE':
            attrs.adminUsers = action.payload;
            attrs.adminUsersByKey = {};
            attrs.adminUsers.map((u)=>{
                attrs.adminUsersByKey[u.id] = true;
            })
            return {...state, ...attrs};

        case 'ORDERS_UPDATE':
            return {...state, orders : _.sortBy(action.payload, 'DocDate').reverse()}
        default:
            return state;
    }
}

