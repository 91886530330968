import React from 'react';
import PropTypes from 'prop-types';
import { fonts, colors } from '../../assets/css/theme';


class TableRow extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className={'TableRow '+this.props.className }>
                {this.props.children}
                <style jsx>{`
                    .TableRow {
                        display: flex;
                        ${fonts.reg}
                        font-size:14px;
                        padding:10px 0px;
                        align-items: center;
                        border-bottom:1px solid ${colors.mid};
                        background: #fff;
                    }
                        
                `}</style>
            </div>
        )
    }
}

TableRow.propTypes = {
    
}

export default TableRow;