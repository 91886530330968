import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableRow from '../common/TableRow';
import TableCell from '../common/TableCell';
import Select from 'react-select'
import { updateUserPermission, removeUser, getLoginLink } from '../../actions/adminAction';
import { colors } from '../../assets/css/theme';
import Button from './Button';

class UserRow extends React.Component {

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.updatePermission = this.updatePermission.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.getLoginLink = this.getLoginLink.bind(this);
        this.state = {
            user : {},
            working : false,
            permissionToBuyFor : null,
            deleting : false
        }
        this.selectRef = React.createRef();
    }
    
    componentDidMount(){
        this.setInternals(this.props);
    }
    UNSAFE_componentWillReceiveProps(newProps){
        this.setInternals(newProps);
    }

    async updatePermission(item){

        try{
            this.setState({
                working : true,
                permissionToBuyFor : item
            })

            


            if(!item){
                await this.props.updateUserPermission(this.props.user.id, null);
            } else if(Array.isArray(item)){
                if(!item.length){
                    await this.props.updateUserPermission(this.props.user.id, null);
                } else {
                    let values = [];
                    item.map((i)=>{
                        if(Array.isArray(i.value)){
                            values.push(i.value[0]);
                        } else {
                            values.push(i.value);
                        }
                        
                    })

                    await this.props.updateUserPermission(this.props.user.id, values);
                }
            } else {
                await this.props.updateUserPermission(this.props.user.id, item.value);
            }
            
            setTimeout(()=>{
                this.setState({
                    working : false
                })
            },3000)
            
        }
        catch(e){
            console.error(e);
            this.setState({
                working : false
            })
        }
    }

    async deleteUser(){
        if(window.confirm('Are you sure? This cannot be undone')){
            
            try {
                this.setState({deleting : true})
                await this.props.removeUser(this.props.user.id);
            }   
            catch(e){
                console.error(e);
                this.setState({deleting : false})
            } 
        }
        
    }

    async getLoginLink(){
        try {
            this.setState({deleting : true})
            let link = await this.props.getLoginLink(this.props.user.email);
            this.setState({deleting : false})
            var text = prompt("Send this link to the customer for them to log in", link);
        }   
        catch(e){
            console.error(e);
            this.setState({deleting : false})
        } 
    }

    setInternals(props){
        let attrs = {};
        let userAllowedCustomer = '';
        attrs.user = props.user || {};

        if(attrs.user && attrs.user.id && props.permissionsByUserId[attrs.user.id]){
            userAllowedCustomer = props.permissionsByUserId[attrs.user.id];

            if(Array.isArray(props.permissionsByUserId[attrs.user.id])){
                attrs.permissionToBuyFor = [];
                props.permissionsByUserId[attrs.user.id].map((id)=>{
                    attrs.permissionToBuyFor.push({
                        label : id+' : '+props.customersByKey[id]?.Name,
                        value : id
                    })
                })
            } else {
                attrs.permissionToBuyFor = {
                    label : userAllowedCustomer+' : '+props.customersByKey[userAllowedCustomer].Name,
                    value : userAllowedCustomer
                };
            }


        }
    

        setTimeout(()=>{
            this.setState({...attrs});
        })

    }

    render(){
        let {email, firstName, lastName, customerId, id, hospitalName} = this.state.user;
        const getLoginButton = ()=>{
            if(!this.props.isSuperAdmin){
                return null;
            }
            if(this.props.adminUsersByKey[id]) return null; //cannot create links for admin
            return (
                <TableCell>
                <button 
                    className="loginLink" 
                    onClick={this.getLoginLink}
                    disabled={this.state.deleting}    
                >
                    <svg  x="0px" y="0px" width="20px" height="20px" viewBox="0 0 512 512" >
                        <path fill="#fff" d="M131.5,472H60.693c-8.538,0-13.689-4.765-15.999-7.606c-3.988-4.906-5.533-11.29-4.236-17.519c20.769-99.761,108.809-172.616,210.445-174.98c1.693,0.063,3.39,0.105,5.097,0.105c1.722,0,3.434-0.043,5.142-0.107c24.853,0.567,49.129,5.24,72.236,13.917c10.34,3.885,21.871-1.352,25.754-11.693c3.883-10.34-1.352-21.871-11.693-25.754c-3.311-1.244-6.645-2.408-9.995-3.512C370.545,220.021,392,180.469,392,136C392,61.01,330.991,0,256,0c-74.991,0-136,61.01-136,136c0,44.509,21.492,84.092,54.643,108.918c-30.371,9.998-58.871,25.546-83.813,46.062c-45.732,37.617-77.529,90.086-89.532,147.743c-3.762,18.066,0.744,36.622,12.363,50.908C25.221,503.847,42.364,512,60.693,512H131.5c11.046,0,20-8.954,20-20C151.5,480.954,142.546,472,131.5,472z M160,136c0-52.935,43.065-96,96-96s96,43.065,96,96c0,51.367-40.554,93.438-91.326,95.885c-1.557-0.028-3.114-0.052-4.674-0.052c-1.564,0-3.127,0.023-4.689,0.051C200.546,229.43,160,187.362,160,136z"/>
                        <path fill="#fff" d="M496.689,344.607c-8.561-19.15-27.845-31.558-49.176-31.607h-62.372c-0.045,0-0.087,0-0.133,0c-22.5,0-42.13,13.26-50.029,33.807c-1.051,2.734-2.336,6.178-3.677,10.193H200.356c-5.407,0-10.583,2.189-14.35,6.068l-34.356,35.388c-7.567,7.794-7.529,20.203,0.085,27.95l35,35.612c3.76,3.826,8.9,5.981,14.264,5.981h65c11.046,0,20-8.954,20-20c0-11.046-8.954-20-20-20h-56.614l-15.428-15.698L208.814,397h137.491c9.214,0,17.235-6.295,19.426-15.244c1.618-6.607,3.648-12.959,6.584-20.596c1.936-5.036,6.798-8.16,12.741-8.16c0.013,0,0.026,0,0.039,0h62.371c5.656,0.013,10.524,3.053,12.705,7.932c5.369,12.012,11.78,30.608,11.828,50.986c0.048,20.529-6.356,39.551-11.739,51.894c-2.17,4.978-7.079,8.188-12.56,8.188c-0.011,0-0.022,0-0.033,0h-63.125c-5.533-0.013-10.716-3.573-12.896-8.858c-2.339-5.671-4.366-12.146-6.197-19.797c-2.571-10.742-13.367-17.366-24.105-14.796c-10.743,2.571-17.367,13.364-14.796,24.106c2.321,9.699,4.978,18.118,8.121,25.738c8.399,20.364,27.939,33.555,49.827,33.606h63.125c0.043,0,0.083,0,0.126,0c21.351-0.001,40.647-12.63,49.18-32.201c6.912-15.851,15.137-40.511,15.072-67.975C511.935,384.434,503.638,360.153,496.689,344.607z"/>
                        <circle fill="#fff" cx="431" cy="412" r="20"/>
                    </svg>

                </button>
                <style jsx>{`
                    .loginLink {
                        width:25px;
                        height: 25px;
                        overflow: hidden;
                        background: #FF6B07;
                        border-radius: 3px;
                        border:none;
                        margin-right:5px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                    }    
                    .loginLink:disabled {
                        background: grey;
                    }

                    `}</style>
            </TableCell>
            )
        }

        return (
            <div className="UserRow" style={{opacity : (this.state.deleting)?0.4:1}}>
                <TableRow >
                        <TableCell>
                            <button 
                                className="delete" 
                                onClick={this.deleteUser}
                                disabled={this.state.deleting}    
                            >
                                <svg x="0px" y="0px" width="16px" height="16px" viewBox="0 0 774.266 774.266">
                                    <path fill="#fff" d="M640.35,91.169H536.971V23.991C536.971,10.469,526.064,0,512.543,0c-1.312,0-2.187,0.438-2.614,0.875C509.491,0.438,508.616,0,508.179,0H265.212h-1.74h-1.75c-13.521,0-23.99,10.469-23.99,23.991v67.179H133.916c-29.667,0-52.783,23.116-52.783,52.783v38.387v47.981h45.803v491.6c0,29.668,22.679,52.346,52.346,52.346h415.703c29.667,0,52.782-22.678,52.782-52.346v-491.6h45.366v-47.981v-38.387C693.133,114.286,670.008,91.169,640.35,91.169zM285.713,47.981h202.84v43.188h-202.84V47.981z M599.349,721.922c0,3.061-1.312,4.363-4.364,4.363H179.282c-3.052,0-4.364-1.303-4.364-4.363V230.32h424.431V721.922z M644.715,182.339H129.551v-38.387c0-3.053,1.312-4.802,4.364-4.802H640.35c3.053,0,4.365,1.749,4.365,4.802V182.339z"/>
                                    <rect fill="#fff"  x="475.031" y="286.593" width="48.418" height="396.942"/>
                                    <rect fill="#fff"  x="363.361" y="286.593" width="48.418" height="396.942"/>
                                    <rect fill="#fff"  x="251.69" y="286.593" width="48.418" height="396.942"/>
                                </svg>
                            </button>
                        </TableCell>
                        <TableCell className="email">{email}</TableCell>
                        <TableCell className="name">{firstName} {lastName}</TableCell>
                        <TableCell className="customer">{(customerId)? `${customerId} | ${hospitalName}` : ''}&nbsp;</TableCell>
                        {getLoginButton()}
                        <div className="control">
                            <Select 
                                ref={this.selectRef}
                                isClearable={true}
                                options={this.props.customersSelect}
                                value={this.state.permissionToBuyFor}
                                onChange={this.updatePermission}
                                isDisabled={this.state.working}
                                placeholder="Allocate to a customer"
                                isMulti={true}
                                classNamePrefix="fvselect"
                            />
                        </div>
                        
                    </TableRow>

                    <style jsx>{`
                        .delete {
                            width:25px;
                            height: 25px;
                            overflow: hidden;
                            background: #e04d4d;
                            border-radius: 3px;
                            border:none;
                            margin-right:5px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            cursor: pointer;
                        }    
                        .delete:disabled {
                            background: grey;
                        }
                        
                    `}</style>
                    
                    <style global jsx>{`
                        .fvselect__multi-value {
                        background:${colors.midBlue}!important;
                            
                        }
                        .fvselect__multi-value__label {
                           color:#fff!important;
                           font-weight:700;
                            
                        }

                    `}</style>
            </div>
        )
    }
}

UserRow.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        permissionsByUserId : state.admin.permissionsByUserId,
        customersSelect : state.admin.customersSelect,
        customersByKey : state.admin.customersByKey,
        isSuperAdmin : state.auth.isSuperAdmin,
        adminUsersByKey : state.admin.adminUsersByKey
    };
}

export default  connect(mapStateProps, { updateUserPermission, removeUser, getLoginLink })(UserRow);
