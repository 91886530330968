/**
 * Iterate the elements in the Firebase Snapshot payload and dispatch the correspondent Action event
 * @param {*} snapShot 
 * @param {*} type 
 * @returns 
 */
export const dispatchCollectionSnapshot = (snapShot, type)=>{
    return async (dispatch) => {
        return new Promise(async(resolve, reject) => {
            let records = [];
            snapShot.forEach((record)=>{
                let r = record.data();
                r.id = record.id;
                records.push(r)
            });
            dispatch({
                type: type,
                payload : records
            });
        }).catch((e) => {
            console.log(e);
        });
    }
}