import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableRow from '../common/TableRow';
import TableCell from '../common/TableCell';
import TableHeader from '../common/TableHeader';
import moment from 'moment';
import ViewButton from '../common/ViewButton';
import Order from '../common/Order';
import Popover from '../common/Popover';
import Status from '../common/Status';
import Select from 'react-select'
import UserRow from './../common/UserRow';
import { effects, colors, fonts } from '../../assets/css/theme';
import AdminUserRow from '../common/AdminUserRow';

import SearchBox from '../common/SearchBox';
import AddNewAdmin from '../common/AddNewAdmin';

class AdminUsers extends React.Component {

    constructor(props){
        super(props);
        this.renderRows = this.renderRows.bind(this);
        this.state = {
            working:false
        }
    }

    renderRows(){
        return this.props.admins
            .filter((admin)=>{
                return (admin.isAdmin);
            }).map((admin, idx)=>{
            return <AdminUserRow user={admin} key={idx} />
        })
        return null;
    }
    render(){
        return (
            <div className="AdminUsers">
                <div className="tabs">
                    <div className="tab">Admin Users</div>
                    
                    
                </div>

               <div className="AdminUsers-wrap">
                    <div style={{display:'flex', justifyContent : 'flex-end', marginBottom : 20}}>
                        <AddNewAdmin></AddNewAdmin>
                    </div>


                    <div className="AdminUsers-records">
                        <TableRow>

                            <TableHeader className="email">Email</TableHeader>
                            <TableHeader className="name">Name</TableHeader>
                            <TableHeader className="superAdmin">Super Admin</TableHeader>
                            <TableHeader className="control">&nbsp;</TableHeader>
                        </TableRow>
                        {this.renderRows()}
                    </div>

                    {/* <PaginationControls
                        style={{marginTop:20}}
                        totalPages={this.state.totalPages}
                        currentPage={this.state.currentPage}
                        onChange={(page)=>{this.setState({currentPage : page})}} 
                    />

                    <Popover 
                        isOpen={this.state.selectedRecord}
                        onClose={()=>{this.setState({selectedRecord : null})}}
                    >
                        <Order order={this.state.selectedRecord} />
                    </Popover> */}

                   
                </div>
                    <style jsx>{`
                    .AdminUsers-wrap {
                        padding:20px;
                        background: #fff;
                        ${effects.shadow1}
                        margin-top:0px;
                        min-height: 70vh;
                    }

                    .tabs {
                        display: flex;
                        position: relative;
                    }
                    .search-area {
                        flex:1;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }

                    .tab {
                        background: #fff;
                        color:${colors.midBlue};
                        width: 220px;
                        height: 45px;
                        padding:0px 10px 0px 20px;
                        display: flex;
                        align-items: center;
                        margin-right:5px;
                        margin-bottom:0px;
                        border-radius: 2px 30px 0px 0px;
                        ${effects.shadow1}
                        ${fonts.bold}
                        font-size:16px;
                        cursor: default;
                        z-index: 1;
                    } 
                       
                    .tab:after {
                        content : "";
                        position: absolute;
                        bottom:-10px;
                        left:0px;
                        right:0px;
                        display: block;
                        height: 10px;
                        background: #fff;
                    }
                    




                    .AdminUsers-records :global(.email){
                        text-align: left;
                        width:200px;
                    }
                    .AdminUsers-records :global(.name){
                        text-align: left;
                        flex:1;
                    }

                    
                    .AdminUsers-records :global(.superAdmin){
                        text-align: center;
                        width:50px;
                       
                    }

                    .AdminUsers-records :global(.control){
                        width: 200px;
                        text-align: right;

                    }

                    



                `}</style>
            </div>
        )
    
    }
}

AdminUsers.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        admins : state.admin.adminUsers
    };
}

export default  connect(mapStateProps, {  })(AdminUsers);
