const INITIAL_STATE = {
    authedUser : null,
    authChecked : false,
    permissionsLoaded : false,
    permissions : {},
    isAdmin : false,
    isSuperAdmin : false,
    isAdminChecked : false
};

export default (state = INITIAL_STATE, action) => {
    let attrs;
    switch (action.type){
        case 'FIREBASE_AUTHSTATE_CHANGE':
            return {...state, authChecked : true, authedUser : action.payload};
        
        case 'USER_PERMISSIONS_UPDATE':
            return { ...state, permissions : action.payload, permissionsLoaded : true  }
        
        case 'AUTH_LOGGED_OUT':
            return { ...state, authUser : null }
        
        case 'IS_ADMIN':
            return {...state, isAdmin : action.payload || false, isAdminChecked : true}

        case 'IS_SUPERADMIN':
            return {...state, isSuperAdmin : action.payload || false, isAdminChecked : true}
        
        case 'RESET':
            return {...INITIAL_STATE, connecting : false, connected : true}
        
        case 'USER_LOGGED_OUT':
            return {...INITIAL_STATE  }
        default:
            return state;
    }
}