import React from 'react';
import PropTypes from 'prop-types';
import { colors, fonts } from '../../assets/css/theme';

class PaginationControls extends React.Component {   

    constructor(props){
        super(props);
        this.setInternals = this.setInternals.bind(this);
        this.state = {
            pages : []
        }
    }
    UNSAFE_componentWillMount(){ }
    
    componentDidMount(){
        this.setInternals(this.props)
    }

    UNSAFE_componentWillReceiveProps(newProps){
        this.setInternals(newProps)
    }

 


    setInternals(props){
        let pages = [];
        for(let i=0; i< props.totalPages; i++){
            pages.push(i);
        }

        this.setState({
            pages : pages
        })
    }
    render(){

        if(this.props.totalPages < 2)return null;

        const renderPages = ()=>{
            return this.state.pages.map((p, idx)=>{
                return <span 
                    key={idx}
                    onClick={()=>{this.props.onChange(p)}}
                    className={(this.props.currentPage===p)? 'pagination active' : 'pagination'}
                >{p+1}

                </span>
            })
        }
        return (
            <div className="PaginationControls" style={{...this.props.style}}>
               
                {renderPages()}
              

                <style jsx>{`
                    
                    .PaginationControls {
                        display: flex;

                    }
                    .PaginationControls :global(span){
                        width:50px;
                        padding:8px;
                        background: ${colors.mid};
                        text-align: center;
                        border-right:1px solid #fff;
                        cursor: pointer;
                        ${fonts.bold}
                        font-size:12px;
                        
                    }
                    .PaginationControls :global(span:hover){
                       background: ${colors.hiBlue};
                    }
                    .PaginationControls :global(span.active){
                       background: ${colors.midBlue};
                       color:#fff;
                       cursor: default;
                       
                    }
                `}</style>
                
            </div>
        )
    }
}

PaginationControls.propTypes = {
    
}

export default PaginationControls;


