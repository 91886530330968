import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addAdmin } from '../../actions/adminAction';
import { colors, effects } from '../../assets/css/theme';
import Button from './Button';

class AddNewAdmin extends React.Component {

    constructor(props){
        super(props);
        this.handleInput = this.handleInput.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.state = {
            value : '',
            working:false,
           
        }

    }
    
    handleInput(e){
        this.setState({
            value : e.target.value
        })
    }
    async handleEnter(){
        try {
            this.setState({working:true});
            await this.props.addAdmin(this.state.value);
            this.setState({working:false, value : ''});
        }
        catch(e){
            console.error(e);
            this.setState({working:false});
        }
    }

    render(){
        return (
            <div className="AddNewAdmin">
                <input
                    ref={(node)=>this.inputbox=node}
                    className="input-box" type="text" 
                    placeholder="Add email address of new admin user" 
                    value={this.state.value} 
                    type="email"
                    onChange={this.handleInput} />
                <Button 
                    working={this.state.working}
                    onClick={this.handleEnter}
                    style={{padding:"8px 22px", borderRadius:"0px 5px 5px 0px"}}
               >Add</Button>
                
                <style jsx>{`
                        .AddNewAdmin {
                            display: flex;
                            ${effects.shadow1}
                            border-radius:4px;
                        }
                        .input-box {
                            padding:5px;
                            border:1px solid ${colors.mid};
                            width: 300px;
                            border-radius: 4px 0px 0px 4px;;
                            font-size:14px;
                            text-align: right;
                            color:${colors.dark};
                            
                            height: 32px;
                        }   
                        .searchControl {
                            width: 32px;
                            background: #fff;
                            border-radius: 0px 4px 4px 0px;
                            border:1px solid ${colors.mid};
                            border-left:none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        } 
                    
                `}</style>
            </div>
        )
    }
}

AddNewAdmin.propTypes = {
    
}

const mapStateProps = (state) => {
    return {

    };
}

export default  connect(mapStateProps, { addAdmin })(AddNewAdmin);
