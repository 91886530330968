import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { effects, colors, fonts } from '../../assets/css/theme';
import SearchBox from '../common/SearchBox';
import Fuse from "fuse.js";
import PaginationControls from '../common/PaginationControls';
import TableRow from '../common/TableRow';
import TableCell from '../common/TableCell';
import TableHeader from '../common/TableHeader';
import moment from 'moment';
import ViewButton from '../common/ViewButton';
import Order from '../common/Order';
import Popover from '../common/Popover';
import Status from '../common/Status';
import Select from 'react-select'
import UserRow from './../common/UserRow';


import { updateUserPermission } from '../../actions/adminAction';
import ToggleOption from '../common/ToggleOption';

class Users extends React.Component {

    constructor(props){
        super(props);
        this.updateFilter = this.updateFilter.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.updatePermission = this.updatePermission.bind(this);
        this.searchFields = [
            "email",
            "firstName",
            "lastName",
            "hospitalName",
            "customerId"
        ]
        this.state = {
            filterText : '',
            paginationCount : 15,
            currentPage : 0,
            totalPages : 1,
            records : [],
            paginationPages : [],
            selectedRecord : null,
            working : false,
            showNew : false
        }
    }

    updateFilter(filterVal){
        this.setState({
            filterText : filterVal,
            currentPage : 0
        }, ()=>{
            setTimeout(()=>{
                this.setInternals(this.props)
            })
        })
    }

    componentDidMount(){ 
        this.setInternals(this.props)
    }
    UNSAFE_componentWillReceiveProps(newProps){
        this.setInternals(newProps)
    }

    async updatePermission(userId, customerId){
        try{
            await this.props.updateUserPermission(userId, customerId);
        }
        catch(e){

        }
    }

    setInternals(props){

        let records = props.records.slice();

        if(this.state.filterText){
            const fuse = new Fuse(records, {
                shouldSort: true,
                tokenize: false,
                threshold: 0.4,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: this.searchFields
              });
            records = fuse.search(this.state.filterText);
        }

        this.setState({
            records : records,
            totalPages : Math.ceil(records.length/this.state.paginationCount)
        });

    }



    renderRows(){

        const formatDate = (str)=>{
            if(!str) return '';
            try {
                return moment(str).format('DD-MM-YYYY');
            }
            catch(e){
                return '';
            }
        }

        let opts = this.props.customers.map((c)=>{
            return {
                    value : c.SAPBP,
                    label : `${c.SAPBP} : ${c.Name}`
                }
            });
        return this.state.records
            .filter((i, idx)=>{
                //pagination filter
                if(idx > (this.state.currentPage * this.state.paginationCount)+(this.state.paginationCount-1)) return false;
                if(idx < this.state.currentPage * this.state.paginationCount) return false;
                return true;
            })
            .filter((i, idx)=>{
                if(this.state.showNew){
                    return (i.status==='new');
                }
                return true;
            })
            .map((r, idx)=>{
                return <UserRow key={idx} user={r.item ? r.item : r}></UserRow>
                return (
                    <TableRow key={idx}>
                        <TableCell className="date">{r.email}</TableCell>
                        <TableCell className="date">{r.firstName}</TableCell>
                        <TableCell className="track">{r.lastName}</TableCell>
                        <TableCell className="po">{r.customerId}</TableCell>
                        <div style={{width:200}}>
                        <Select 
                            isClearable={true}
                            options={opts}
                            value={this.props.permissionsByUserId[r.id]}
                            onChange={(val)=>{
                                let customerId = (val) ? val.value : null;
                                //val= null = no customer
                                this.updatePermission(r.id, customerId)
                            }}
                        />
                        </div>
                    </TableRow>
                )
            })
    }

    render(){

        return (
            <div className="Users">
                <div className="tabs">
                    <div className="tab">Users</div>
                    <div className="search-area">
                        <SearchBox
                            placeholder="Search by name, email, or customer"
                            value={this.state.filterText}
                            onChange={(val)=>{this.updateFilter(val)}}
                        />
                    </div>
                   
                </div>

               <div className="Users-wrap">
                    <div style={{display:'flex', justifyContent : 'flex-end', marginBottom : 20}}>
                    <ToggleOption 
                            label="Show only new users"
                            value={this.state.showNew}
                            onClick={()=>{
                                this.setState({showNew : !this.state.showNew}, ()=>{
                                    this.setInternals(this.props)
                                })
                                
                            }}
                            reverse={true}
                        />
                    </div>
                     
                    <div className="Users-records">
                        <TableRow>

                            <TableHeader className="email">Email</TableHeader>
                            <TableHeader className="name">Name</TableHeader>
                            <TableHeader className="customer">Allocated to customer</TableHeader>
                            <TableHeader className="control">Allocated to customer</TableHeader>
                        </TableRow>
                        {this.renderRows()}
                    </div>

                    <PaginationControls
                        style={{marginTop:20}}
                        totalPages={this.state.totalPages}
                        currentPage={this.state.currentPage}
                        onChange={(page)=>{this.setState({currentPage : page})}} 
                    />

                    <Popover 
                        isOpen={this.state.selectedRecord}
                        onClose={()=>{this.setState({selectedRecord : null})}}
                    >
                        <Order order={this.state.selectedRecord} />
                    </Popover>

                   
                </div>
                    <style jsx>{`
                    .Users-wrap {
                        padding:20px;
                        background: #fff;
                        ${effects.shadow1}
                        margin-top:0px;
                        min-height: 70vh;
                    }

                    .tabs {
                        display: flex;
                        position: relative;
                    }
                    .search-area {
                        flex:1;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }

                    .tab {
                        background: #fff;
                        color:${colors.midBlue};
                        width: 220px;
                        height: 45px;
                        padding:0px 10px 0px 20px;
                        display: flex;
                        align-items: center;
                        margin-right:5px;
                        margin-bottom:0px;
                        border-radius: 2px 30px 0px 0px;
                        ${effects.shadow1}
                        ${fonts.bold}
                        font-size:16px;
                        cursor: default;
                        z-index: 1;
                    } 
                       
                    .tab:after {
                        content : "";
                        position: absolute;
                        bottom:-10px;
                        left:0px;
                        right:0px;
                        display: block;
                        height: 10px;
                        background: #fff;
                    }
                    




                    .Users-records :global(.email){
                        text-align: left;
                        flex:1;
                    }
                    .Users-records :global(.name){
                        text-align: left;
                        width:180px;
                    }

                    
                    .Users-records :global(.customer){
                        text-align: left;
                        display: none;
                       
                    }

                    .Users-records :global(.control){
                        width:250px;
                        flex:1;
                        text-align: left;
                    }

                    



                `}</style>
            </div>
        )
    }
}

Users.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        records : state.admin.users,
        permissionsByUserId : state.admin.permissionsByUserId,
        customers :state.admin.customers
    };
}

export default  connect(mapStateProps, { updateUserPermission })(Users);
