import React from 'react';
import PropTypes from 'prop-types';
import ColTitle from './ColTitle';
import { colors, fonts } from '../../assets/css/theme';

class TableHeader extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){

        
        
        return (
            <div className={'TableHeader '+this.props.className }>
                {this.props.children}
                <style jsx>{`
                    .TableHeader {
                        ${fonts.bold}
                        font-size:14px;
                        color:${colors.midBlue};
                        text-align: center;
                `}</style>
            </div>
        )
    }
}

TableHeader.propTypes = {
    
}

export default TableHeader;