import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

class Popover extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){
        if(!this.props.isOpen) return null;

     
        return (
            <div className="Popover" onClick={this.props.onClose}>
                <div className="Popover-content-wrap">
                    <div className="Popover-content">
                        {this.props.children}
                        <div className="Popover-foot">
                            <Button 
                                onClick={this.props.onClose}
                                style={{width :130}}
                                working={false}
                            >Done</Button>
                        </div>
                    </div>
                </div>
                
                
                <style jsx>{`

                    @keyframes PopoverIn {
                        0% {
                            opacity: 0;
                            
                        }
                      
                        100% {
                            opacity: 1;
                           
                        }
                    }

                    .Popover {
                        position: fixed;
                        top:0px;
                        bottom:0px;
                        left:0px;
                        right:0px;
                        background: rgba(0,0,0,0.8);
                        display: flex;
                        flex-direction: column;
                        padding:10px!important;
                        animation: PopoverIn 0.3s;
                        animation-fill-mode: forwards;
                        animation-timing-function: ease-in-out;
                        min-height:1px;
                        align-items:center;
                        z-index: 1;
                       
                    }  
                    .Popover-content-wrap {
                        margin: auto!important;
                        overflow-y: auto!important;
                        overflow-x:hidden;
                        
                    }
                    .Popover-content {
                       
                        border-radius: 5px;
                        padding:30px!important;
                        background: #fff;
                        position: relative;
                        
                    }  
                   
                   

                    
                    .Popover-foot {
                        margin-top:30px;
                        text-align: right;
                    }
                    

                `}</style>
            </div>
        )
    }
}

Popover.propTypes = {
    
}

export default Popover;