import React from 'react';
import PropTypes from 'prop-types';
import Menu from './Menu';


class MainPanel extends React.Component {   

    constructor(props){
        super(props);
       
    }

    render(){
        return (
            <div className="MainPanel">
                <div className="sidebar">
                    <Menu />

                </div>
                <div className="main">
                {this.props.children}
                </div>
                <style jsx>{`
                    .MainPanel {
                        display: flex;
                        max-width: 1400px;
                        padding:40px;
                        margin:auto;
                        min-height: calc( 100vh - 110px );
                        
                    }   
                    .sidebar {
                        width:220px;
                        margin-right:40px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;

                    } 
                    .main {
                        flex:1;
                    }

                    @media print {
                        .sidebar {
                            display: none;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

MainPanel.propTypes = {
    
}

export default MainPanel;