import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateLineItems } from '../../actions/cartAction';
import incrSrc from './../../assets/img/plus.svg';
import decrSrc from './../../assets/img/minus.svg';
import { fonts, colors } from '../../assets/css/theme';

class OrderStepper extends React.Component {

    constructor(props){
        super(props);
        this.getQty = this.getQty.bind(this);
        this.incr = this.incr.bind(this);
        this.decr = this.decr.bind(this);

    }

    getQty(){
        if(!this.props.lineItem) return 0;
        return this.props.lineItem.Quantity;
    }
    incr(){
        let qty = this.getQty() || 0; 
        this.props.updateLineItems(this.props.itemCode, qty+1)
    }
    decr(){
        let qty = this.getQty() || 0;
        qty=qty-1;
        if(qty<1){
            qty = 0;
        }
        this.props.updateLineItems(this.props.itemCode, qty)
    }

    render(){
       

        
        return (
            <div className="OrderStepper">
                
                <div onClick={this.decr}>
                    <img src={decrSrc} alt="" className="control"/>
                </div>
                <p className="currentVal">{this.getQty()}</p>
                <div onClick={this.incr}>
                    <img src={incrSrc} alt="" className="control"/>
                </div>

                <style jsx>{`
                    .OrderStepper {
                        margin:0 auto;
                        width: 100px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }
                    .control {
                        width: 32px;
                        height: 32px;
                        cursor: pointer;
                    }
                    .currentVal {
                        ${fonts.bold}
                        font-size:16px;
                        color:${colors.dark};
                        margin:0px;
                    }

                    
                `}</style>
            </div>
        )
    }
}

OrderStepper.propTypes = {
    
}

const mapStateProps = (state, props) => {
    return {
        lineItem : state.cart.itemsByKey[props.itemCode] || null
    };
}

export default  connect(mapStateProps, { updateLineItems })(OrderStepper);
