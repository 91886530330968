import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import history from "./common/history";

import './../assets/css/styles.scss';

import { loadFirebase } from '../actions/appAction';
import { initProfile } from '../actions/profileAction';
import { fonts, colors } from '../assets/css/theme';
import CentralFrame from './common/CentralFrame';
import SignIn from './common/SignIn';
import CreateUserProfile from './screens/CreateUserProfile';
import AwaitingActivation from './common/AwaitingActivation';
import HeaderBar from './common/HeaderBar';
import Dashboard from './screens/Dashboard';
import Catalogue from './screens/Catalogue';
import Users from './screens/Users';
import Orders from './screens/Orders';
import Invoices from './screens/Invoices';
import MainPanel from './common/MainPanel';
import Invoice from './screens/Invoice';
import AdminUsers from './screens/AdminUsers';


const TEST = (props)=>{
    return (
        <p>TEST</p>
    )
}
class ArcadiaPortal extends React.Component {

    constructor(props){
        super(props);

    }
    UNSAFE_componentWillMount(){ 
        this.props.loadFirebase();

    }
    
    componentDidMount(){
    }


    render(){
        
        if(!this.props.connected || !this.props.authChecked) {
            return (
                <CentralFrame  style={{background : colors.loBlue}}>
                    <p className="message">Loading</p>
                    <style jsx>{`
                        .message{
                            ${fonts.reg}
                            text-align: center;
                            color:#fff;
                            max-width: 450px;
                            margin:auto;
                        }
    
                        
                    `}</style>
                </CentralFrame>
                
            )
        }

        //auth has been checked - no user present
        if(!this.props.authedUser) {
            return (
                <CentralFrame style={{background : colors.loBlue}}>
                    <SignIn />
                </CentralFrame>
            )
        }

        if(!this.props.profileChecked){
            return (
                <CentralFrame >
                    <p className="message">Loading</p>
                    <style jsx>{`
                        .message{
                            ${fonts.reg}
                            text-align: center;
                            color:#fff;
                            max-width: 450px;
                            margin:auto;
                        }
    
                        
                    `}</style>
                </CentralFrame>
            )
        }

        if(!this.props.profile) {
            return (
                <CentralFrame style={{background : colors.midHi}}>
                    <CreateUserProfile />
                </CentralFrame>
            )
        }

        if(!this.props.isAdminChecked) {
            return (
                <CentralFrame >
                    <p className="message">Loading</p>
                    <style jsx>{`
                        .message{
                            ${fonts.reg}
                            text-align: center;
                            color:#fff;
                            max-width: 450px;
                            margin:auto;
                        }
    
                        
                    `}</style>
                </CentralFrame>
            )
        }

        if(!this.props.isAdmin) {
            return (
                <CentralFrame style={{background : colors.midHi}}>
                    <AwaitingActivation />
                </CentralFrame>
            )
        }
        
        return (
            <div className="ArcadiaPortal">
                <Router>
                    <div>
                        <HeaderBar />
                        <MainPanel>
                            <Routes>
                                <Route path="/" element={<Users />} />
                                <Route path="/orders" element={<Orders />} />
                                <Route path="/admin-users" element={<AdminUsers />} />
                            </Routes>
                        </MainPanel>
                        
                    </div>
                </Router>
                <style jsx>{`
                    .ArcadiaPortal {
                        ${fonts.reg}
                        color : ${colors.dark};
                    }
                    @media print {
                        .ArcadiaPortal {
                            
                        }
                    }
                    
                `}</style>
            </div>
        )
    }
}

ArcadiaPortal.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        connected : state.app.connected,
        authChecked : state.auth.authChecked,
        authedUser : state.auth.authedUser,
        profile : state.profile.profile,
        profileChecked : state.profile.profileChecked,
        isAdmin : state.auth.isAdmin,
        isAdminChecked : state.auth.isAdminChecked
    };
}

export default  connect(mapStateProps, { loadFirebase, initProfile })(ArcadiaPortal);
