import React from 'react';
import PropTypes from 'prop-types';
import { fonts } from '../../assets/css/theme';

class TableCell extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className={(this.props.className)? `TableCell ${this.props.className}` : `TableCell`}>
                {this.props.children}

                <style jsx>{`
                    .TableCell {
                        ${fonts.bold};
                        font-size:14px;
                        text-align: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }    
                    
                `}</style>
            </div>
            
        )
    }
}

TableCell.propTypes = {
    
}

export default TableCell;