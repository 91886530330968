import _ from 'lodash';

const INITIAL_STATE = {
    profile : null,
    invoices : [],
    deliveries : [],
    credits : [],
    orders : [],
    priceList : {},
    items : [],
    itemsByKey : {},
    accessRequests : {}
};

export default (state = INITIAL_STATE, action) => {
    let attrs = {};
    switch (action.type){
        case 'CUSTOMER_PRICE_LIST_UPDATE':
            return {...state, priceList : action.payload};
        case 'CUSTOMER_PROFILE_UPDATE':
            return {...state, profile : action.payload};
        case 'CUSTOMER_INVOICES_UPDATE':
            return {...state, invoices : action.payload};
        case 'CUSTOMER_DELIVERIES_UPDATE':
            return {...state, deliveries : action.payload};
        case 'CUSTOMER_CREDITS_UPDATE':
            return {...state, credits : action.payload};
        case 'CUSTOMER_ORDERS_UPDATE':
            return {...state, orders : _.sortBy(action.payload, 'DocDate').reverse() };
        case 'ITEMS_UPDATE':
            attrs.items = _.sortBy(action.payload, 'ItemName');
            attrs.itemsByKey = {}
            attrs.items.forEach((i)=>{
                attrs.itemsByKey[i.ItemCode] = i;
            })
            return {...state, ...attrs };
        case 'CUSTOMER_ACCESS_REQUESTS_UPDATE':
            attrs.accessRequests = {}
            action.payload.forEach((i)=>{
                attrs.accessRequests[i.ItemCode] = i;
            })
            return {...state, ...attrs };
        case 'USER_LOGGED_OUT':
            return {...INITIAL_STATE}
        default:
            return state;
    }
}