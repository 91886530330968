import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../assets/css/theme';

class Status extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){
        let styles = {};

        if(this.props.children=='Closed'){
            
            styles.color = colors.mid
        }
        if(this.props.children=='New'){
            styles.color = colors.orange
        }
        if(this.props.children=='Open'){
            styles.color = colors.green
        }
        
        return (
            <div className="Status"
                style={styles}
            >
                {this.props.children}
            </div>
        )
    }
}

Status.propTypes = {
    
}

export default Status;