import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import logoSrc from './../../assets/img/logo-blue.svg'
import Button from './Button';

import { colors, fonts, effects } from '../../assets/css/theme';
import { logoutUser } from '../../actions/authAction';


class HeaderBar extends React.Component {

    constructor(props){
        super(props);

    }

    render(){

        const renderName = ()=>{
            let parts = [];
            // if(this.props.authedUser && this.props.authedUser.email){
            //     parts.push(this.props.authedUser.email);
            // }

            if(this.props.profile && this.props.profile.firstName){
                parts.push(this.props.profile.firstName+" "+this.props.profile.lastName)
            }
            return parts.join(" - ");
        }

        const renderEmail = ()=>{
            return (
                <div className="userHospital">
                    {this.props.authedUser.email}
                    <style jsx>{`
                        .userHospital {
                            color:${colors.midBlue};
                            ${fonts.bold};
                            text-align: right;
                        }    
                    `}</style>    
                </div> 
            )
        }

        const renderStatus = ()=>{
            if(this.props.enabledForCustomer){
                return 'ACTIVE';
            }

            
            if(this.props.profile && this.props.profile.firstName){
                return 'PENDING'
            }

            return 'NEW'

            
        }
        return (
            <div className="HeaderBar">
                <div className="top">
                    <div className="logo">
                        <img className="logoImg" src={logoSrc} alt=""/>
                    </div>
                    <div className="userArea">
                        <div className="userArea-inner">
                            <div className="userName">{renderName()}</div>
                            {renderEmail()}
                            <div className="button">
                                <svg width="8" height="6" viewBox="0 0 8.643 6.461">
                                    <path className="downArrow"  d="M1723.785,38l4.555,6.461L1732.428,38Z" transform="translate(-1723.785 -38)" />
                                </svg>
                                
                                <div className="signOut">
                                    <div className="logoutButton" onClick={this.props.logoutUser}>
                                        Log out
                                    </div>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className="blue-bar">
                    
                </div>
                
                <style jsx>{`
                    .top {
                        display: flex;
                        justify-content: space-between;
                        padding:5px 20px;
                    }
                    .logoImg {
                        width: 214px;
                        height:72px;
                    }
                    .blue-bar {
                        background: ${colors.loBlue};
                        color:#fff;
                        ${fonts.bold};
                        text-align: right;
                        padding:10px 20px;
                        font-size:12px;
                    }
                    .userArea {
                        display: flex;
                        align-items: center;
                    }
                    .userName {
                        color:${colors.loBlue};
                        ${fonts.bold};
                        text-align: right;
                        line-height: 2em;
                    }  
                    .userArea-inner {
                        position: relative;
                        padding-right:30px;
                    }
                    .downArrow {
                        fill : ${colors.mid};
                    }
                    .button {
                        position: absolute;
                        right:0px;
                        top:3px;
                        bottom:0px;
                        width: 20px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 5px;
                        border:1px solid ${colors.mid};
                    }
                    .button:hover {
                        border-radius: 5px 5px 0px 0px;
                    }
                   
                    .signOut {
                        opacity: 0;
                        background: #fff;
                        ${effects.shadow1}
                        position: absolute;
                        top:100%;
                        right:-1px;
                        width:120px;
                        height: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 120px;
                        border:1px solid ${colors.mid};
                        border-radius: 5px 0px 5px 5px;
                        transform: scale(0);
                        transition: all 0.3s;
                        transform-origin: top right;

                    }
                    .button:hover :global(.signOut){
                        opacity: 1;
                        transform: scale(1);
                    }
                    
                    .logoutButton {
                        ${fonts.bold};
                        background: #11ABDD;
                        color: #fff;
                        font-size: 14px;
                        padding: 6px 22px;
                        border: none;
                        border-radius: 5px;
                        display: inline-block;
                        cursor: pointer;
                    }
                    .logoutButton:hover {
                        opacity: 0.9;
                    }
                    @media print {
                        .userArea, .blue-bar {
                            display: none;
                        }

                    }
                `}</style>
            </div>
        )
    }
}

HeaderBar.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        authedUser : state.auth.authedUser,
        profile : state.profile.profile,
        enabledForCustomer : state.auth.enabledForCustomer
    };
}

export default  connect(mapStateProps, { logoutUser })(HeaderBar);
