import React from 'react';
import PropTypes from 'prop-types';
import { fonts, colors } from '../../assets/css/theme';

class Label extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="Label" style={{...this.props.style}}>
                {this.props.children}
                <style jsx>{`
                    .Label {
                        ${fonts.bold}
                        color: ${colors.midBlue};
                        font-size:18px;   
                    }
                `}</style>
            </div>
        )
    }
}

Label.propTypes = {
    
}

export default Label;