import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { colors, fonts, effects } from '../../assets/css/theme';
import withRouter from '../../util/withRouter';

class Menu extends React.Component {

    constructor(props){
        super(props);

    }

    render(){

        const renderAdminLink = ()=>{
            if(this.props.isSuperAdmin) return (
                <NavLink className="menu-link" activeClassName="active" to="/admin-users" >Admin Users</NavLink>
            )
        }

        return (
            <div className="Menu">
                
                <NavLink className="menu-link" to="/">Users</NavLink>
                <NavLink className="menu-link" to="/orders" >Web Orders</NavLink>
                {renderAdminLink()}
                {/* <NavLink className="menu-link" activeClassName="active" to="/orders">Orders</NavLink>
                <NavLink className="menu-link" activeClassName="active" to="/deliveries">Deliveries</NavLink>
                <NavLink className="menu-link" activeClassName="active" to="/invoices">Invoices</NavLink> */}

                <style jsx>{`

                    .Menu :global(.menu-link) {
                        display: block;
                        padding:10px;
                        background: #fff;
                        ${fonts.bold}
                        color:${colors.midBlue};
                        font-size:16px;
                        border-radius: 0px 20px 20px 0px;
                        margin-bottom:15px;
                        ${effects.shadow1}
                        text-decoration: none;
                        transition: all 0.3s;
                    }    
                    .Menu :global(.menu-link.active),
                    .Menu :global(.menu-link.active:hover){
                        background: ${colors.loBlue};
                        color: #fff;
                    } 

                    .Menu :global(.menu-link:hover){
                        background: ${colors.midBlue};
                        color: #fff;
                    } 
                    
                `}</style>
            </div>
        )
    }
}

Menu.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        isSuperAdmin : state.auth.isSuperAdmin
    };
}

export default  withRouter(connect(mapStateProps, {  })(Menu));
