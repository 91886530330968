import React from 'react';
import PropTypes from 'prop-types';
import { fonts, colors, effects } from '../../assets/css/theme';
import tickSrc from './../../assets/img/tick.svg';
import { TweenMax, Elastic } from '../../gsap-bonus/all';

class AwaitingActivation extends React.Component {   

    constructor(props){
        super(props);
    }
    
    componentDidMount(){ 
        TweenMax.from(this.node, 2, { scale : 0.001, transformOrigin : "50% 50%", ease: Elastic.easeOut })

    }

    render(){
        return (
            <div className="AwaitingActivation">
                <h2 className="title">Thank you.</h2>
                <p className="intro">Our team have your details and will be in touch as soon as your account is activated</p>
                <img ref={(node)=>this.node=node} className="tick" src={tickSrc} alt=""/>
                <style jsx>{`
                    .AwaitingActivation {
                        max-width: 725px;
                        padding:20px;
                    }
                   

                    .title {
                        ${fonts.bold};
                        font-size:26px;
                        color:${colors.loBlue};
                        text-align: center;
                        margin-bottom:40px;
                    }
                    .intro {
                        ${fonts.reg};
                        font-size:26px;
                        color:${colors.loBlue};
                        text-align: center;
                        margin-bottom:40px;
                    }
                    .tick{
                        width: 205px;
                        height:205px;
                        margin:auto;
                        display: block;
                    }
                    
                `}</style>
            </div>
        )
    }
}

AwaitingActivation.propTypes = {
    
}

export default AwaitingActivation;