import React from 'react';
import PropTypes from 'prop-types';
import { fonts, colors } from '../../assets/css/theme';

class ColTitle extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="ColTitle" style={{...this.props.style}}>
                {this.props.children}
                <style jsx>{`
                    .ColTitle {
                        ${fonts.bold}
                        color : ${colors.midBlue};
                        font-size:14px;
                        
                    }
                    @media print {
                        .ColTitle {
                            font-size:12px;
                        }
                    }
                `}</style>
            </div>
        )
    }
}

ColTitle.propTypes = {
    
}

export default ColTitle;