import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TableRow from '../common/TableRow';
import TableCell from '../common/TableCell';
import Select from 'react-select'
import { updateUserPermission, removeAdmin } from '../../actions/adminAction';
import Button from './Button';

class AdminUserRow extends React.Component {

    constructor(props){
        super(props);
        this.removeAdmin = this.removeAdmin.bind(this)
        this.state = {
            working : false
        }
    }
  
    async removeAdmin(){
        if(window.confirm('Are you sure?')){
            try {
                this.setState({working:true});
                await this.props.removeAdmin(this.props.user.id);
                this.setState({working:false});
            }
            catch(e){
                console.error(e);
                this.setState({working:false});
            }
        }    
    }
    

    render(){
        let {email, firstName, lastName, isSuperAdmin} = this.props.user;

        const isSuper = ()=>{
            if(isSuperAdmin){
                return (
                    <svg width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19.86,4a11.25,11.25,0,1,0,.19.19ZM11.91,21.84a9.93,9.93,0,1,1,9.93-9.93A9.93,9.93,0,0,1,11.91,21.84Z" fill="#63c17c"/>
                        <path d="M17.36,8.39a.65.65,0,0,0-.93,0h0l-5.82,5.82L7.55,11.15a.66.66,0,0,0-.94.93l3.53,3.53a.66.66,0,0,0,.94,0h0l6.28-6.29a.63.63,0,0,0,.05-.91A.06.06,0,0,0,17.36,8.39Z" fill="#63c17c"/>
                    </svg>
                )
            }
            return null
        }
        return (
            <div className="AdminUserRow">
                <TableRow >
                        <TableCell className="email">{email}</TableCell>
                        <TableCell className="name">{firstName} {lastName}</TableCell>
                        <TableCell className="superAdmin">{isSuper()}</TableCell>
                        <div className="control">
                            <Button onClick={this.removeAdmin} working={this.state.working}>Remove Admin</Button>
                        </div>
                    </TableRow>
            </div>
        )
    }
}

AdminUserRow.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        permissionsByUserId : state.admin.permissionsByUserId,
        customersSelect : state.admin.customersSelect,
        customersByKey : state.admin.customersByKey
    };
}

export default  connect(mapStateProps, { updateUserPermission, removeAdmin })(AdminUserRow);
