import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/database';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment'

export const loadFirebase = ()=> {

    return (dispatch)=> {

        

        return new Promise((resolve, reject)=>{

            dispatch({type: 'FIREBASE_INITIALISING'})


            const collectionSnapshot = (snapShot, type)=>{
                let records = [];
                    snapShot.forEach((record)=>{
                        let r = record.data();
                        r.id = record.id;
                        records.push(r)
                    });
                    dispatch({
                        type: type,
                        payload : records
                    });
            }

            const docSnapshop = (snapShot, type)=>{
                let record = snapShot.data();
                dispatch({
                    type: type,
                    payload : record
                });
            }

            const initFirebase = (config)=>{


                if (!firebase.apps.length) {
                    firebase.initializeApp(config);
                    firebase.app().functions('europe-west2');
                    resolve(true)
                } else {
                    resolve(true)
                }



                firebase.database().ref('.info/connected').on('value', (snap)=> {
                    dispatch({type: 'FIREBASE_CONNECTED', payload: snap.val()})
                })


                firebase.auth().onAuthStateChanged((user)=> {
                    dispatch({type: 'FIREBASE_AUTHSTATE_CHANGE', payload: user});
                    user.getIdToken(true);
                })
                
                
                

       

                //listen to the profile
                firebase.auth().onAuthStateChanged(async (user)=> {
                    if(user){
                        //load their profile
                        let profileListener = await firebase.firestore().collection('adminUsers').doc(user.uid)
                        .onSnapshot(
                            async (snapShot)=>{
                                              
                                let profile = snapShot.data();
                                
                                dispatch({
                                    type: 'USER_PROFILE_UPDATE',
                                    payload : profile
                                });
                                await firebase.auth().currentUser.getIdToken(true);
                            
                                try {
                                    
                                    let token = await firebase.auth().currentUser.getIdTokenResult(true);

                                    dispatch({
                                        type: 'IS_ADMIN',
                                        payload : token.claims.isAdmin
                                    });


                                    dispatch({
                                        type: 'IS_SUPERADMIN',
                                        payload : token.claims.isSuperAdmin
                                    });


                                    if(token.claims.isAdmin){

                                       
                                        // firebase.firestore().collection('BPs').doc(token.claims.customerId)
                                        // .onSnapshot((snapShot)=>{docSnapshop(snapShot, 'CUSTOMER_PROFILE_UPDATE')})
                                        
                                        firebase.firestore().collection('users')
                                        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'USERS_UPDATE')})

                                     
                                        
                                        firebase.firestore().collection('BPs')
                                        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMERS_UPDATE')})

                                        firebase.firestore().collection('Orders')
                                        .where('Status', "in", ['Open', 'New'])
                                        .where('source', "==", 'WEB')
                                        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'ORDERS_UPDATE')})

                                        firebase.firestore().collection('permissions')
                                        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'USER_PERMISSIONS_UPDATE')})

                                        // firebase.firestore().collection('Deliveries')
                                        // .where('SAPBP',"==", token.claims.customerId)
                                        // .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_DELIVERIES_UPDATE')})

                                        // firebase.firestore().collection('Credits')
                                        // .where('SAPBP',"==", token.claims.customerId)
                                        // .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_CREDITS_UPDATE')})

                                        // firebase.firestore().collection('Orders')
                                        // .where('SAPBP',"==", token.claims.customerId)
                                        // .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_ORDERS_UPDATE')})

                                        // firebase.firestore().collection('accessRequests')
                                        // .where('SAPBP',"==", token.claims.customerId)
                                        // .where('createdAt', ">", moment().subtract(1, 'week').toDate())
                                        // .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'CUSTOMER_ACCESS_REQUESTS_UPDATE')})

                                    } else {
                                        // dispatch({ type: 'CUSTOMER_PROFILE_UPDATE', payload : null});
                                        // dispatch({ type: 'CUSTOMER_INVOICES_UPDATE', payload : null});
                                        // dispatch({ type: 'CUSTOMER_DELIVERIES_UPDATE', payload : null});
                                        // dispatch({ type: 'CUSTOMER_CREDITS_UPDATE', payload : null});
                                        // dispatch({ type: 'CUSTOMER_ORDERS_UPDATE', payload : null});
                                        // dispatch({
                                        //     type: 'ENABLED_FOR_CUSTOMER',
                                        //     payload : false
                                        // });
                                    }


                                    if(token.claims.isSuperAdmin){
                                        firebase.firestore().collection('adminUsers')
                                        .onSnapshot((snapShot)=>{collectionSnapshot(snapShot, 'ADMIN_USERS_UPDATE')})
                                    }


                                   

                                }
                                catch(e){
                                    console.error(e);
                                    dispatch({
                                        type: 'USERS_UPDATE',
                                        payload : []
                                    });
                                    dispatch({
                                        type: 'CUSTOMERS_UPDATE',
                                        payload : []
                                    });
                                }
                                
                            }
                        );
                        dispatch({
                            type: 'USER_PROFILE_LISTENER_UPDATE',
                            payload : profileListener
                        });  
                        

                        firebase.firestore()
                        .collection('Items')
                        .onSnapshot((snapShot)=>{ collectionSnapshot(snapShot, 'ITEMS_UPDATE') })

                        
                        await firebase.firestore().collection('permissions').doc(user.uid)
                        .onSnapshot((snapShot)=>{docSnapshop(snapShot, 'USER_PERMISSIONS_UPDATE')})

                        await firebase.firestore().collection('userCarts').doc(user.uid)
                        .onSnapshot((snapShot)=>{docSnapshop(snapShot, 'USER_CART_UPDATE')})





                        
                    } else {
                        dispatch({
                            type: 'USER_PROFILE_UPDATE',
                            payload : null
                        });
                        dispatch({
                            type: 'USER_PROFILE_LISTENER_UPDATE',
                            payload : null
                        });  
                    }



                })

            
            }


            axios.get('/__/firebase/init.json')
                .then(
                    (resp)=> {
                if(resp.data.databaseURL){
                //it's a valid firebase object
                initFirebase(resp.data);
            } else {
                initFirebase({
                    apiKey: "AIzaSyBPfsM9vXuIz4eDjQsIi-VeUVIpiz8sMyY",
                    authDomain: "arcadia-dev-193e0.firebaseapp.com",
                    databaseURL: "https://arcadia-dev-193e0.firebaseio.com",
                    projectId: "arcadia-dev-193e0",
                    storageBucket: "arcadia-dev-193e0.appspot.com",
                    messagingSenderId: "1012169350742"
                });
            }
        }
        )
        .catch((e)=> {
                //default for local testing
                initFirebase({
                    apiKey: "AIzaSyBPfsM9vXuIz4eDjQsIi-VeUVIpiz8sMyY",
                    authDomain: "arcadia-dev-193e0.firebaseapp.com",
                    databaseURL: "https://arcadia-dev-193e0.firebaseio.com",
                    projectId: "arcadia-dev-193e0",
                    storageBucket: "arcadia-dev-193e0.appspot.com",
                    messagingSenderId: "1012169350742"
                });

        })

        })
        

        //load auth from /__/firebase/init.json - JSON representation of the configuration
        //https://firebase.googleblog.com/2017/04/easier-configuration-for-firebase-on-web.html
        //Maybe load it and if it fails (which it will locally, fallback to the default test one).



    }
}
