import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { effects, colors, fonts } from '../../assets/css/theme';
import SearchBox from '../common/SearchBox';
import Fuse from "fuse.js";
import PaginationControls from '../common/PaginationControls';
import { updateOrderStatusFromCustomerPortalToRemoved } from '../../actions/adminAction';
import TableRow from '../common/TableRow';
import TableCell from './../common/TableCell';
import TableHeader from '../common/TableHeader';
import moment from 'moment';
import ViewButton from '../common/ViewButton';
import Order from '../common/Order';
import Popover from '../common/Popover';
import Status from '../common/Status';
import ToggleOption from '../common/ToggleOption'

import ReactTable from "react-table-6";
import 'react-table-6/react-table.css'

class Orders extends React.Component {

    constructor(props){
        super(props);
        this.updateFilter = this.updateFilter.bind(this);
        this.renderRows = this.renderRows.bind(this);
        this.toggleOnlyOpen = this.toggleOnlyOpen.bind(this);
        this.updateOrderStatusFromCustomerPortalToRemoved = this.updateOrderStatusFromCustomerPortalToRemoved.bind(this);
        this.searchFields = [
            "CustomerRef",
            "Total",
            "OrderPlacedByName",
            "SearchString"
        ]
        this.state = {
            filterText : '',
            paginationCount : 15,
            currentPage : 0,
            totalPages : 1,
            records : [],
            paginationPages : [],
            selectedRecord : null,
            onlyOpen : false
        }
    }

    updateFilter(filterVal){
        this.setState({
            filterText : filterVal,
            currentPage : 0
        }, ()=>{
            setTimeout(()=>{
                this.setInternals(this.props)
            })
        })
    }

    componentDidMount(){ 
        this.setInternals(this.props)
    }
    UNSAFE_componentWillReceiveProps(newProps){
        this.setInternals(newProps)
    }

    setInternals(props){
        let records = props.records.slice();

        records = records.map((r)=>{
            r.SearchString = '';
            r.Lines = r.Lines.map((l)=>{
                r.SearchString += this.props.itemsByKey[l.ItemCode].ItemName+" ";
                return l;
            })
            return r
        })

        if(this.state.onlyOpen){
            records = records.filter((r)=>{
                let allowedStatus = ['New', 'Open'];
                return (allowedStatus.indexOf(r.Status)>-1)
            })
        }

        if(this.state.filterText){
            const fuse = new Fuse(records, {
                shouldSort: true,
                tokenize: false,
                threshold: 0.4,
                location: 0,
                distance: 100,
                maxPatternLength: 32,
                minMatchCharLength: 1,
                keys: this.searchFields
              });
            records = fuse.search(this.state.filterText);
        } 

       
   

        this.setState({
            records : records,
            totalPages : Math.ceil(records.length/this.state.paginationCount)
        });

    }
    toggleOnlyOpen(){
        this.setState({
            onlyOpen : !this.state.onlyOpen,
            currentPage : 0
        }, ()=>{
            setTimeout(()=>{
                this.setInternals(this.props);
            })
        })
    }
    renderRows(){

        const formatDate = (str)=>{
            if(!str) return '';
            try {
                return moment(str).format('DD-MM-YYYY');
            }
            catch(e){
                return '';
            }
        }

        return this.state.records
            .filter((i, idx)=>{
                //pagination filter
                if(idx > (this.state.currentPage * this.state.paginationCount)+(this.state.paginationCount-1)) return false;
                if(idx < this.state.currentPage * this.state.paginationCount) return false;
                return true;
            })
            .map((r, idx)=>{
                return (
                    <TableRow key={idx}>
                        <TableCell className="date">{formatDate(r.DocDate)}</TableCell>
                        <TableCell className="po">{r.CustomerRef}</TableCell>
                        <TableCell className="name">{r.OrderPlacedByName}</TableCell>
                        <TableCell className="status">{<Status>{r.Status}</Status>}</TableCell>
                        <TableCell className="total">£{r.Total.toFixed(2)}</TableCell>
                        <TableCell className="control"><ViewButton onClick={()=>{this.setState({ selectedRecord : r})}} /></TableCell>
                    </TableRow>
                )
            })
    }

    async updateOrderStatusFromCustomerPortalToRemoved(orderWebNum)  {
        if(window.confirm('Are you sure? This will archive this order.')){
            try {
                await this.props.updateOrderStatusFromCustomerPortalToRemoved(orderWebNum)
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    render(){

        const renderCurrentOrderTab = ()=>{
            if(!this.props.cart || !this.props.cart.Total) return '';

            return (
                <div className="currentOrder" onClick={()=>{
                    this.props.history.push('/catalogue?cart')
                }}>
                    Current Order: £{this.props.cart.Total.toFixed(2)}
                    <img className="currentOrder-img" src={require('./../../assets/img/follow.svg')} alt=""/>
                    <style jsx>{`
                        .currentOrder {
                            display: flex;
                            ${fonts.bold}
                            color:${colors.midBlue};
                            align-items: center;
                            padding-left:10px;
                            font-size:12px;
                            
                            cursor: pointer;
                            text-transform: uppercase;
                        }
                        .currentOrder:hover .currentOrder-img  {
                            transform: scale(1.2);
                        }
                        .currentOrder-img {
                            width:20px;
                            height: 20px;
                            margin-left:5px;
                            transition: all 0.3s;
                        }
                        
                    `}</style>
                </div>
            )
        }
        
        return (
            <div className="Orders">
                <div className="tabs">
                    <div className="tab">Web Orders</div>
                    
                    
                </div>

               <div className="Orders-wrap">
                
                <p>These are orders that have been placed on the web-portal but not yet picked up by SAP</p>
                <p>After SAP has collected the new orders, these will be removed when the daily update is completed.</p>
                <ReactTable 
                        data={this.props.orders}
                        filterable
                        columns={
                            [
                            {
                                Header: 'Web Order No',
                                accessor: 'WebOrderNum' // String-based value accessors!
                            },
                            {
                                Header: 'Customer No',
                                accessor: 'SAPBP' // String-based value accessors!
                            },
                            {
                                Header: 'Customer',
                                id : 'customerName',
                                accessor: (row)=>{
                                    if(this.props.customers[row.SAPBP]){
                                        return this.props.customers[row.SAPBP].Name
                                    }
                                    return '';
                                } // String-based value accessors!
                            },
                            {
                                Header: 'Customer Ref',
                                accessor: 'CustomerRef' // String-based value accessors!
                            },
                            {
                                Header: 'Order Placed',
                                id : 'orderDate',
                                accessor: (row)=>{
                                    return moment(row.DocDate).format('DD-MM-YYYY');
                                } 
                            },
                            {
                                Header: 'Placed By',
                                accessor: 'OrderPlacedByName' // String-based value accessors!
                            },
                            {
                                Header: 'TAX',
                                id : 'TaxTotal',
                                accessor: (row)=>{
                                    return '£'+row.TaxTotal.toFixed(2);
                                } 
                            },
                            {
                                Header: 'Total',    
                                id: 'Total',
                                accessor: (row)=>{
                                    return '£'+row.Total.toFixed(2);
                                }  // String-based value accessors!
                            },
                            {
                                Header: 'Status',
                                id : 'status',
                                accessor: 'Status',
                                Cell: row => {
                                    row.styles['fontWeight'] = row.value === 'New' ? 'bold' : 'normal';
                                    return row.value;      
                                }  
                            },
                            {
                                Header: 'Action',
                                accessor: (row) => {
                                    return ( <div>
                                        <button className="delete jsx-4020240521" onClick={() => this.updateOrderStatusFromCustomerPortalToRemoved(row.WebOrderNum)}>
                                        <svg x="0px" y="0px" width="16px" height="16px" viewBox="0 0 774.266 774.266" className="jsx-4020240521 jsx-3822518216"><path fill="#fff" d="M640.35,91.169H536.971V23.991C536.971,10.469,526.064,0,512.543,0c-1.312,0-2.187,0.438-2.614,0.875C509.491,0.438,508.616,0,508.179,0H265.212h-1.74h-1.75c-13.521,0-23.99,10.469-23.99,23.991v67.179H133.916c-29.667,0-52.783,23.116-52.783,52.783v38.387v47.981h45.803v491.6c0,29.668,22.679,52.346,52.346,52.346h415.703c29.667,0,52.782-22.678,52.782-52.346v-491.6h45.366v-47.981v-38.387C693.133,114.286,670.008,91.169,640.35,91.169zM285.713,47.981h202.84v43.188h-202.84V47.981z M599.349,721.922c0,3.061-1.312,4.363-4.364,4.363H179.282c-3.052,0-4.364-1.303-4.364-4.363V230.32h424.431V721.922z M644.715,182.339H129.551v-38.387c0-3.053,1.312-4.802,4.364-4.802H640.35c3.053,0,4.365,1.749,4.365,4.802V182.339z" className="jsx-4020240521 jsx-3822518216"></path><rect fill="#fff" x="475.031" y="286.593" width="48.418" height="396.942" className="jsx-4020240521 jsx-3822518216"></rect><rect fill="#fff" x="363.361" y="286.593" width="48.418" height="396.942" className="jsx-4020240521 jsx-3822518216"></rect><rect fill="#fff" x="251.69" y="286.593" width="48.418" height="396.942" className="jsx-4020240521 jsx-3822518216"></rect></svg>
                                        </button>
                                        <style global="false">{` 
                                            .delete.jsx-4020240521 {
                                                width: 25px;
                                                height: 25px;
                                                overflow: hidden;
                                                background: #11ABDD;
                                                border-radius: 3px;
                                                border: none;
                                                margin-right: 5px;
                                                display: -webkit-box;
                                                display: -webkit-flex;
                                                display: -ms-flexbox;
                                                display: flex;
                                                -webkit-box-pack: center;
                                                -webkit-justify-content: center;
                                                -ms-flex-pack: center;
                                                justify-content: center;
                                                -webkit-align-items: center;
                                                -webkit-box-align: center;
                                                -ms-flex-align: center;
                                                align-items: center;
                                                cursor: pointer;
                                            }

                                        `}</style>
                                    </div> )
                                },
                                id: 'action'
                            }
                            ]
                        }
                        
                        SubComponent={row => {
                            return (
                              <div style={{ padding: "0px 20px 40px 20px" }}>
                                <ReactTable
                                  data={row.original.Lines}
                                  defaultPageSize={5}
                                  columns={[
                                    {
                                        Header: 'Item Code',
                                        accessor: 'ItemCode' // String-based value accessors!
                                    },
                                    {
                                        Header: 'Quantity',
                                        accessor: 'Quantity' // String-based value accessors!
                                    },
                                    {
                                        Header: 'UnitPrice',
                                        id: 'UnitPrice',
                                        accessor: (row)=>{
                                            return '£'+row.UnitPrice.toFixed(2);
                                        }  // String-based value accessors!
                                    },
                                    {
                                        Header: 'LineTotal',
                                        id: 'LineTotal',
                                        accessor: (row)=>{
                                            return '£'+row.LineTotal.toFixed(2);
                                        }  // String-based value accessors!
                                    },
                                    
                                  ]
                                  
                                }
                                
                                />
                              </div>
                            );
                          }}
                ></ReactTable>
                    
                  

                </div>
                    <style jsx>{`
                    .Orders-wrap {
                        padding:20px;
                        background: #fff;
                        ${effects.shadow1}
                        margin-top:0px;
                    }
                    .toggleWrap {
                        display: flex;
                        justify-content: flex-end;
                        margin-bottom:20px;
                    }

                    .tabs {
                        display: flex;
                        position: relative;
                    }
                    .search-area {
                        flex:1;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }

                    .tab {
                        background: #fff;
                        color:${colors.midBlue};
                        width: 220px;
                        height: 45px;
                        padding:0px 10px 0px 20px;
                        display: flex;
                        align-items: center;
                        margin-right:5px;
                        margin-bottom:0px;
                        border-radius: 2px 30px 0px 0px;
                        ${effects.shadow1}
                        ${fonts.bold}
                        font-size:16px;
                        cursor: default;
                        z-index: 1;
                    } 
                       
                    .tab:after {
                        content : "";
                        position: absolute;
                        bottom:-10px;
                        left:0px;
                        right:0px;
                        display: block;
                        height: 10px;
                        background: #fff;
                    }

                    .Orders-records :global(.date){
                        text-align: left;
                        width:100px;
                    }
                    .Orders-records :global(.po){
                        flex:1;
                        text-align: left;
                        
                    }

                    .Orders-records :global(.name){
                        width:160px;
                       
                    }

                    .Orders-records :global(.price){
                        width:100px;
                    }
                    

                    
                    .Orders-records :global(.status){
                        width:100px;
                    }
                    .Orders-records :global(.total){
                        width:100px;
                        text-align: right;
                    }

                    .Orders-records :global(.control){
                        width:50px;
                        text-align: right;
                    }

                    



                `}</style>
            </div>
        )
    }
}

Orders.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        orders : state.admin.orders,
        customers : state.admin.customersByKey,
        records : state.customer.orders,
        itemsByKey : state.customer.itemsByKey,
        cart : state.cart.cart
    };
}

export default  connect(mapStateProps, { updateOrderStatusFromCustomerPortalToRemoved })(Orders);
