import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fonts, colors } from '../../assets/css/theme';
import Input from './Input';

class CustomerRef extends React.Component {

    constructor(props){
        super(props);

    }

    render(){
        let valueString = this.props.value;
        if(!this.props.editable && !this.props.value){
            valueString='None given'
        }

        const renderControl = ()=>{
            if(!this.props.editable){
                return (
                    <p>
                        {valueString}
                        <style jsx>{`
                            p {
                                ${fonts.bold}
                                color : ${colors.midBlue};
                                margin:0;
                            }
                            
                        `}</style>
                    </p>
                )
            } else {
                return (
                    <div style={{width:200}}>
                        <Input 
                            value={this.props.value}
                            style={{padding:'5px 10px'}}
                            onChange={this.props.onChange}
                        ></Input>
                    </div>
                )
            }
        }

        

        return (
            
            <div className="CustomerRef" style={{...this.props.style}}>
                
                <p className="label">Your Purchase Order Number:</p>
                {renderControl()}
                <style jsx>{`
                    .CustomerRef {
                        display: flex;
                        align-items: center;
                    }
                    .label {
                        ${fonts.bold}
                        margin:0;
                        margin-right:10px;
                        
                    }    
                `}</style>
            </div>
        )
    }
}

CustomerRef.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
    
    };
}

export default  connect(mapStateProps, {  })(CustomerRef);
