import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export const initProfile = ()=> {
    return async (dispatch, getState)=> {
        

        let cancelListener = getState().profile.listener;
        if(cancelListener){
            cancelListener();
        }

        firebase.auth().onAuthStateChanged(async (user)=> {
            if(user){
                //load their profile
                let profileListener = await firebase.firestore().collection('adminUsers').doc(user.uid)
                .onSnapshot(
                    (snapShot)=>{
                        let data = snapShot.data();
                        dispatch({
                            type: 'USER_PROFILE_UPDATE',
                            payload : data
                        });
                        //when the profile changes, refresh tokens to make sure claims are avaialable
                        user.getIdToken(true);
                        
                    }
                );
                dispatch({
                    type: 'USER_PROFILE_LISTENER_UPDATE',
                    payload : profileListener
                });  


            } else {
                dispatch({
                    type: 'USER_PROFILE_UPDATE',
                    payload : null
                });
                dispatch({
                    type: 'USER_PROFILE_LISTENER_UPDATE',
                    payload : null
                });  
            }

                      
        })

    }
}


export const saveProfile = (profile)=>{
    
    return async (dispatch, getState)=> {
        return new Promise(async(resolve, reject)=>{
            let userId = getState().auth.authedUser.uid;  
            try {
                if(!profile.createdAt) {
                    profile.createdAt = firebase.firestore.FieldValue.serverTimestamp();
                }
                profile.email = getState().auth.authedUser.email;
                profile.updatedAt = firebase.firestore.FieldValue.serverTimestamp();
                await firebase.firestore().collection('adminUsers').doc(userId).set(profile,
                {merge : true});
                resolve(true);
            }
            catch(e){
                console.error(e);
                reject(e);
            }
        })
        

    }
}