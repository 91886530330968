import React from 'react';
import PropTypes from 'prop-types';
import { fonts, colors } from '../../assets/css/theme';

class Input extends React.Component {   

    constructor(props){
        super(props);
    }

    render(){
        return (
            <div className="wrap">
                <input type="text"
                    className="input"
                    value={this.props.value || ''}
                    onChange={(e)=>{this.props.onChange(e.target.value)}}
                    placeholder={this.props.placeholder}
                    style={{...this.props.style}}
                / >
            <style jsx>{`
                .wrap {
                    width: 100%;
                }
                .input {
                    ${fonts.reg}
                    font-size:15px;
                    width: 100%;
                    display: block;
                    padding:10px 10px;
                    border-radius: 3px;
                    border:1px solid ${colors.mid};
                    margin:5px 0px;
                }
                
            `}</style>
        
            </div>
        )
    }
}

Input.propTypes = {
    
}

export default Input;