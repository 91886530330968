import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select, {components} from 'react-select'
import _ from 'lodash';

const CustomOptionComponent = (props) =>{
    let { label } = props.data;   
    return (
        <div ref={props.innerRef} {...props.innerProps} >
            <p style={{fontSize:12, margin:0, padding:10, border: '1px solid #ccc'}}>
                {label.AltAddressName}{(label.AltAddressName)? <br/>:''}
                {label.AltAddressName2}{(label.AltAddressName2)? <br/>:''}
                {label.StreetNo}{(label.StreetNo)? <br/>:''}
                {label.Street}{(label.Street)? <br/>:''}
                {label.Block}{(label.Block)? <br/>:''}
                {label.City}{(label.City)? <br/>:''}
                {label['Zip Code']}{(label['Zip Code'])? <br/>:''}
            </p>
        </div>
    )
}


const CustomSingleValueComponent = (props) =>{

    let { label } = props.data;
    return (
        <div ref={props.innerRef} {...props.innerProps} >
            <p style={{fontSize:12, margin:0, padding:10}}>
                {label.AltAddressName}{(label.AltAddressName)? <br/>:''}
                {label.AltAddressName2}{(label.AltAddressName2)? <br/>:''}
                {(label.AltAddressName || label.AltAddressName2)? <br/>:''}
                {label.StreetNo}{(label.StreetNo)? <br/>:''}
                {label.Street}{(label.Street)? <br/>:''}
                {label.Block}{(label.Block)? <br/>:''}
                {label.City}{(label.City)? <br/>:''}
                {label['Zip Code']}{(label['Zip Code'])? <br/>:''}
            </p>
        </div>
    )
}

const ValueContainer = (props) =>{
    let selected = _.find(props.options, { value : props.selectProps.value});

    if(selected && selected.label){
        let { label } = selected;
        return (
            <div ref={props.innerRef} {...props.innerProps} >
                <p style={{fontSize:12, margin:0, padding:10, ...props.style}}>
                    {label.AltAddressName}{(label.AltAddressName)? <br/>:''}
                    {label.AltAddressName2}{(label.AltAddressName2)? <br/>:''}
                    {(label.AltAddressName || label.AltAddressName2)? <br/>:''}
                    {label.StreetNo}{(label.StreetNo)? <br/>:''}
                    {label.Street}{(label.Street)? <br/>:''}
                    {label.Block}{(label.Block)? <br/>:''}
                    {label.City}{(label.City)? <br/>:''}
                    {label['Zip Code']}{(label['Zip Code'])? <br/>:''}
                </p>
            </div>
        )
    }
    return 'Please select an address';
   
}



class AddressSelector extends React.Component {

    constructor(props){
        super(props);

    }
    
    render(){
        if(!this.props.profile || !this.props.profile.Addresses) return null;
        
        if(!this.props.editable){
            
            return <ValueContainer
                style={{padding:0}}
                options={this.props.profile.Addresses.map((a)=>{
                return {
                        value : a.AddressCode,
                        label : a
                    }
                })}
            selectProps={{value : this.props.value}}
            ></ValueContainer>
        }
        return (
            <div className="AddressSelector">
                <Select 
                    options={this.props.profile.Addresses.map((a)=>{
                    return {
                            value : a.AddressCode,
                            label : a
                        }
                    })}
                    value={this.props.value}
                    defaultValue={this.props.profile[this.props.defaultKey]}
                    components={{ Option: CustomOptionComponent, SingleValue : CustomSingleValueComponent, ValueContainer : ValueContainer }}
                    selectOption={CustomOptionComponent}
                    onChange={this.props.onChange}
                    getOptionValue={(option) => {
                        
                        
                    }}
                    getOptionLabel={(opt)=>{
                        return 'getOption'
                    }}
                />
            </div>
        )

    }
}

AddressSelector.propTypes = {
    
}

const mapStateProps = (state) => {
    return {
        profile : state.customer.profile
    };
}

export default  connect(mapStateProps, {  })(AddressSelector);
