import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import history from "./../components/common/history";

export const loginWithEmail = (email)=> {

    let url = `${window.location.protocol}//${window.location.host}/`
    

    return (dispatch)=> {
        return new Promise((resolve, reject)=>{

        var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be whitelisted in the Firebase Console.
            url: url,
            // This must be true.
            handleCodeInApp: true,
            iOS: {
              bundleId: 'com.example.ios'
            },
            android: {
              packageName: 'com.example.android',
              installApp: true,
              minimumVersion: '12'
            }
          };

          firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
            .then(function() {
                window.localStorage.setItem('emailForSignIn', email);
                resolve(true);
            })
            .catch(function(error) {
                console.error(error);
                reject(error);
            });
        })
        

      
    }
}


export const logoutUser = ()=>{
    return (dispatch)=>{
        dispatch({
            type: 'AUTH_WORKING'
        });

        firebase.auth().signOut()
            .then(
                ()=>{

                    dispatch({
                        type: 'USER_LOGGED_OUT'
                    });
                },
                (err)=>{
                    console.log('sign out error', err);
                }
            )
    }
}
